import _ from 'lodash';
import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import helpers from '../helpers/index';
import store from '../vuex/store';
const flatRoutes = _.flatMap(routes, route => {
    var _a;
    if (_.isEmpty(route.children)) {
        return route;
    }
    return [route].concat(((_a = route.children) === null || _a === void 0 ? void 0 : _a.map(child => {
        var _a;
        return ({
            ...child,
            meta: {
                auth: (_a = route.meta) === null || _a === void 0 ? void 0 : _a.auth,
            },
        });
    })) || []);
});
const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach(async (to, from, next) => {
    var _a, _b, _c, _d, _e;
    const route = flatRoutes.find(r => r.name === to.name);
    if (!route) {
        return;
    }
    let redirectPathQuery = _.get(to, 'query.redirectPath', '');
    redirectPathQuery = helpers.removeURLParameter(redirectPathQuery, 'token');
    if (redirectPathQuery) {
        await store.dispatch('auth/setRedirectUrl', redirectPathQuery);
    }
    if ((_a = to.meta) === null || _a === void 0 ? void 0 : _a.authMult) {
        await store.dispatch('auth/validateMultiUser').catch(() => { });
        sessionStorage.setItem('redirect-mult', to.fullPath);
        // Crutch for single-user tests
        if (to.name !== 'profile-activity') {
            if (!store.state.auth.multiUser)
                return next('/logout');
        }
    }
    if (_.includes(['logout'], route.name) || /multi-user/.test((typeof route.name == 'string' && route.name) || '')) {
        return next();
    }
    if (!((_b = to.meta) === null || _b === void 0 ? void 0 : _b.allowSuspended) && store.state.auth.isSuspended) {
        next('/account/payments');
    }
    if (_.isNil(store.state.auth.authorized)) {
        const initialPath = to.path;
        try {
            await store.dispatch('auth/init', {
                initialPath,
                clearCookies: !((_c = to.meta) === null || _c === void 0 ? void 0 : _c.authMult),
                redirectPath: redirectPathQuery,
            });
            if (initialPath === '/account/payments') {
                try {
                    await store.dispatch('auth/getAccountIsPayable');
                    return store.state.auth.isPayable ? next() : next('/account');
                }
                catch (e) {
                    next('/account');
                }
            }
            store.dispatch('auth/getAccountIsPayable');
            if (!((_d = to.meta) === null || _d === void 0 ? void 0 : _d.allowSuspended) && store.state.auth.isSuspended) {
                next('/account/payments');
            }
            return next();
        }
        catch (e) {
            if (route.path === '*' || ((_e = route.meta) === null || _e === void 0 ? void 0 : _e.auth)) {
                return next('/logout');
            }
            else {
                return next();
            }
        }
    }
    return next(); // eslint-disable-line consistent-return
});
router.afterEach((to) => {
    var _a;
    if ((_a = to.meta) === null || _a === void 0 ? void 0 : _a.title) {
        document.title = to.meta.title;
    }
});
export default router;
