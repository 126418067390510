import { computed, defineComponent, ref } from 'vue';
import { OrButtonV3 as OrButton, OrLoaderV3 as OrLoader, OrNotificationV3 as OrNotification, } from '@onereach/ui-components';
import LoginContainer from '../layouts/LoginContainer.vue';
import _ from 'lodash';
import { useStore } from 'vuex';
import { useAuth } from '../composables/useAuth';
import { authenticateExternalProvider } from '../api/auth_provider';
import authService from '../api/auth';
import config from '../config';
import helpers from '../helpers/index';
import TranslatableError from '../errors/TranslatableError';
export default defineComponent({
    components: {
        OrNotification,
        LoginContainer,
        OrLoader,
        OrButton,
    },
    setup() {
        const { getProviderByName } = useAuth();
        const store = useStore();
        const authorized = computed(() => store.state.auth.authorized);
        const token = computed(() => store.state.auth.token);
        const authError = computed(() => store.state.auth.authError);
        const error = ref('');
        const authenticating = ref(false);
        const redirectPath = ref('');
        return {
            getProviderByName,
            store,
            authorized,
            token,
            authError,
            error,
            authenticating,
            redirectPath,
        };
    },
    watch: {
        authorized: {
            immediate: true,
            handler(value) {
                if (value) {
                    // this.authorizeRedirect();
                }
            },
        },
        authError(value) {
            if (value) {
                this.authenticating = false;
                this.error = 'The email / password combination is not valid.';
            }
        },
    },
    async created() {
        const { code, token, entityId, tokenType } = this.$route.query;
        let state;
        try {
            state = JSON.parse(this.$route.query.state);
        }
        catch (error) {
            throw new TranslatableError('errors.login.Invalid or malformed OAuth state parameter', error);
        }
        const providerName = this.$route.params.providerName;
        const provider = this.getProviderByName(providerName);
        if (_.isEmpty(provider)) {
            this.error = 'Unsupported provider';
            return;
        }
        try {
            if (token) {
                // In case if provider retruned Single User Token we use this one
                if (tokenType === 'multi-user') {
                    const user = await authService.validateMultiUserToken(token.toString());
                    return this.store.dispatch('auth/signInWithMultToken', ({
                        user,
                        keepLoggedIn: false,
                    }));
                }
                await this.signIn({
                    provider,
                    entityId,
                    state,
                    token,
                    userInfo: { identityProvider: providerName },
                });
            }
            else {
                if (_.isEmpty(code)) {
                    this.error = 'No Authentication code';
                    return;
                }
                // in case of code (get retruned from provider and used to authenticate on our side via okta/provider backend)
                let redirectUri = `${window.location.origin}${window.location.pathname}`;
                let { userId, accountId } = state;
                // authenticating/decoding "code" to OR user
                const userAccount = await authenticateExternalProvider(provider === null || provider === void 0 ? void 0 : provider.providerPath, code, redirectUri, {
                    userId,
                    accountId,
                });
                if (userAccount.tokenType === 'multi-user') {
                    return this.store.dispatch('auth/signInWithMultToken', ({
                        user: userAccount,
                        keepLoggedIn: sessionStorage.getItem('keepLoggedIn') === 'true',
                    }));
                }
                await this.signIn({
                    provider,
                    entityId: userAccount.entityId,
                    state,
                    token: userAccount.token,
                    userInfo: { identityProvider: providerName },
                });
            }
            if (window.opener)
                window.close(); // close window if it was login overlay
        }
        catch (error) {
            this.error = _.get(error, 'body.error');
        }
    },
    methods: {
        async signIn({ provider, entityId, state, token, userInfo }) {
            this.assignRedirectPath(provider, entityId, state);
            await this.store.dispatch('auth/signInWithToken', ({
                token,
                allowGuestLogin: true,
                userInfo,
            }));
            if (!this.error && !this.authError) {
                this.authorizeRedirect(state);
            }
        },
        authorizeRedirect(state) {
            if (_.isEmpty(state))
                state = this.$route.query;
            if (state) {
                const isChild = _.get(state, 'isChild', false);
                if (isChild) {
                    window.close();
                    return;
                }
            }
            if (config.ADD_AUTH_TOKEN) {
                this.redirectPath = helpers.addQueryParams(this.redirectPath, `token=${encodeURIComponent(this.token)}`);
            }
            window.location.href = this.redirectPath;
        },
        assignRedirectPath(provider, entityId, state) {
            const redirectPath = provider.redirectPath || config.AUTH_UI_URL;
            let uri = `${redirectPath}${entityId ? entityId : ''}`;
            if (state) {
                const queryParams = _.map(state, (value, key) => `${key}=${encodeURIComponent(value)}`).join('&');
                uri = helpers.addQueryParams(uri, queryParams);
            }
            this.redirectPath = uri;
        },
        logout() {
            this.$router.push('/logout');
        },
    },
});
