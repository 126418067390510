import _ from 'lodash';
import TranslatableError from './TranslatableError';
import RethrownError from './RethrownError';
class UnpackedError extends RethrownError {
    static unpackErrorMessageFrom(error, skipTranslatableErrors) {
        var _a;
        let message = (_a = error === null || error === void 0 ? void 0 : error.message) !== null && _a !== void 0 ? _a : ('' + error);
        if (error instanceof TranslatableError) {
            const innerErrorMessage = UnpackedError.unpackErrorMessageFrom(error.innerError, skipTranslatableErrors);
            message = skipTranslatableErrors ? innerErrorMessage : (message + '\n' + innerErrorMessage);
            // eslint-disable-next-line
        }
        else if ('cause' in error && _.isFunction(error.cause)) { // unpack SDK error
            // eslint-disable-next-line
            const cause = error.cause();
            const errorMessage = _.get(cause, 'response.data.error');
            if (typeof errorMessage === 'string')
                message += '\nCause: ' + errorMessage;
        }
        return message;
    }
    constructor(error, skipTranslatableErrors = false) {
        const e = error instanceof Error ? error : new Error('' + error);
        super(UnpackedError.unpackErrorMessageFrom(error, skipTranslatableErrors), e, true);
        this.error = error;
        this.name = e.name;
        Object.setPrototypeOf(this, UnpackedError.prototype);
    }
}
export default UnpackedError;
