import * as Sentry from '@sentry/vue';
import config from './config';
import UnpackedError from './errors/UnpackedError';
import store from './vuex/store';
import pkg from '../package.json';
import _ from 'lodash';
export default class SentryApi {
    static async init(app, router) {
        try {
            Sentry.init({
                app,
                dsn: config.SENTRY_DSN,
                environment: config.SENTRY_ENV,
                replaysSessionSampleRate: config.SENTRY_REPLAY_SESSIONS,
                replaysOnErrorSampleRate: config.SENTRY_ERROR_REPLAY_SESSIONS,
                beforeSend(event) {
                    if (SentryApi.isDevMode()) {
                        return null;
                    }
                    // add tags
                    event.tags = {
                        username: _.get(store, 'state.auth.username'),
                        feature: config.FEATURE,
                        accountId: _.get(store, 'state.auth.accountId'),
                        role: _.get(store, 'state.auth.role'),
                        userId: _.get(store, 'state.auth.userId'),
                    };
                    return event;
                },
                release: `${config.SENTRY_PROJECT}@${pkg.version}`,
                integrations: [
                    Sentry.browserTracingIntegration({ router }),
                    Sentry.replayIntegration(),
                ],
                attachStacktrace: true,
                autoSessionTracking: true,
                maxBreadcrumbs: config.SENTRY_MAX_BREADCRUMBS,
                tracesSampleRate: config.SENTRY_TRACES_SAMPLE_RATE,
            });
        }
        catch (e) {
            console.error(`Can not initiate Sentry due to: ${e}`);
        }
    }
    static captureException(err) {
        const unpackedError = new UnpackedError(err);
        if (!SentryApi.isDevMode()) {
            Sentry.captureException(unpackedError);
        }
        console.error(unpackedError);
    }
    static isDevMode() {
        //return false;
        return process.env.NODE_ENV === 'development';
    }
}
